<template>
  <div style="overflow-y: scroll">

    <div class="py-6" style="padding-bottom: 96px !important;">
      <div></div>
      <template v-if="step===0">
        <div class="text-h6 px-4 mt-16">
          {{ $t('ChooseType') }}
        </div>
        <div class="px-4 mt-2 text-body-2 text--secondary">
          {{ $t('HotPotBottomSelection') }}！
        </div>
        <div class="mt-4 px-4 mt-12">
          <v-card
            @click="selectType(t.name)" class="pa-4 mb-2" v-for="t in type" :key="t.name"
            :color="selectedTypeName===t.name?'grey darken-3':''"
            elevation="0"
          >
            <div class="d-flex align-center justify-center">
              <div class="mr-4">

                <div class="text-body-1">
                  {{ t.name }}
                </div>
                <div class="text-body-2 text--secondary">
                  {{ t.desc }}
                </div>
                <div
                  style="display: grid;grid-auto-flow: column;grid-gap: 4px"
                >
                  <v-card
                    v-if="t.recommended"
                    color="primary black--text"
                    class="mt-1 px-1 text-caption"
                    style="width: fit-content; white-space: nowrap;"
                  >
                    {{ $t('RecommendedShop') }}
                  </v-card>
                  <v-card
                    color="grey lighten-2 black--text"
                    class="mt-1 px-1 text-caption text-truncate"
                    style="width: fit-content; white-space: nowrap;"
                  >
                    {{ $t('OnRequest') }} {{ t.maxFlavor }}
                    <template v-if="t.maxFlavor < 2">
                      {{ $t('Flavor') }}
                    </template>
                    <template v-else>
                      {{ $t('Flavors') }}
                    </template>
                  </v-card>
                </div>

              </div>
              <v-spacer></v-spacer>
              <div class="text-body-1 font-weight-bold flex-shrink-0 amber--text text--lighten-4">
                ~{{ t.price | priceDisplay }}
              </div>
            </div>
          </v-card>
        </div>
      </template>
      <template v-if="step===1">
        <div class="text-h6 px-4">
          {{ $t('CustomizeYourBase') }}
        </div>
        <div
          style="display: grid;grid-auto-flow: column;grid-gap: 12px;grid-auto-columns:min-content"
          class="mt-4 px-4 overflow-hidden overflow-x-auto"
        >
          <v-card
            @click="selectedTypeName=t.name" class="pa-4" v-for="t in type" :key="t.id"
            :color="selectedTypeName===t.name?'grey darken-3':''"
            elevation="0"
          >
            <div style="height: 100%;white-space: nowrap" class="d-flex align-center justify-center text-body-2">
              {{ t.name }}
            </div>
          </v-card>
        </div>
        <div class="px-4">
          <div
            class="my-10 mx-8 "
            style="padding: 12%;
                 background: url('img/background/6.png') center / contain no-repeat"
          >
            <template v-if="maxFlavors===1">
              <div
                :style="selectedImage[0]?{
                  backgroundImage:'url('+GlobalConfig.resourceRoot+selectedImage[0]+')'
                }:{
                  background:'rgba(255,255,255,0.15)'
                }"
                style="width: 100%;background: left / cover;border-radius: 100% 100% 100% 100%"
              >
                <v-responsive :aspect-ratio="1"></v-responsive>
              </div>
            </template>
            <template v-if="maxFlavors===2">
              <div style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr));">
                <div
                  :style="selectedImage[0]?{
                  backgroundImage:'url('+GlobalConfig.resourceRoot+selectedImage[0]+')'
                }:{
                  background:'rgba(255,255,255,0.15)'
                }"
                  style="width: 100%;background: left / cover;border-radius: 100% 0 0 100%/50%"
                >
                  <v-responsive :aspect-ratio="0.5"></v-responsive>
                </div>
                <div
                  :style="selectedImage[1]?{
                  backgroundImage:'url('+GlobalConfig.resourceRoot+selectedImage[1]+')'
                }:{
                  background:'rgba(255,255,255,0.2)'
                }"
                  style="width: 100%;background: right / cover;border-radius: 0 100% 100% 0/50%"
                >
                  <v-responsive :aspect-ratio="0.5"></v-responsive>
                </div>
              </div>

            </template>
            <template v-if="maxFlavors===3">
              <div style="border-radius: 50%;width:100%;position: relative;overflow: hidden">
                <v-responsive :aspect-ratio="1">
                  <div

                    style="width: 58%;height: 58%;position: absolute;left: 50%;top: 50%;
                    transform-origin: 0% 0%;transform: rotate(0deg) skewX( -30deg);overflow: hidden"
                  >
                    <div
                      :style="selectedImage[2]?{
                  backgroundImage:'url('+GlobalConfig.resourceRoot+selectedImage[2]+')'
                }:{
                  background:'rgba(255,255,255,0.1)'
                }"
                      style="transform:translate(-50%,-50%) skewX(30deg) ;width: 200%;height: 200%;background: bottom / cover"
                    >
                    </div>

                  </div>
                  <div
                    style="width: 58%;height: 58%;position: absolute;left: 50%;top: 50%;
                    transform-origin: 0% 0%;transform: rotate(120deg) skewX( -30deg);overflow: hidden"
                  >
                    <div
                      :style="selectedImage[0]?{
                  backgroundImage:'url('+GlobalConfig.resourceRoot+selectedImage[0]+')'
                }:{
                  background:'rgba(255,255,255,0.12)'
                }"
                      style="transform:translate(-50%,-50%) skewX(30deg)  rotate(-120deg);width: 200%;height: 200%;background: bottom / cover"
                    >
                    </div>
                  </div>
                  <div

                    style="width: 58%;height: 58%;position: absolute;left: 50%;top: 50%;
                    transform-origin: 0% 0%;transform: rotate(240deg) skewX( -30deg);overflow: hidden"
                  >
                    <div
                      :style="selectedImage[1]?{
                  backgroundImage:'url('+GlobalConfig.resourceRoot+selectedImage[1]+')'
                }:{
                  background:'rgba(255,255,255,0.15)'
                }"
                      style="transform:translate(-50%,-50%) skewX(30deg) rotate(-240deg);width: 200%;height: 200%;background: bottom / cover"
                    >
                    </div>

                  </div>
                </v-responsive>

              </div>

            </template>


          </div>
          <div class="d-block overflow-hidden" style="height: 100%">
            <div class="d-flex align-center">
              <div class="text-truncate text-h6">
                {{ selectedType.name }}
              </div>
              <v-spacer></v-spacer>
              <div class="text-h6 text-no-wrap">{{ totalPrice| priceDisplay }}</div>
            </div>
          </div>
          <div class="text-body-2  mt-2 text--secondary">
            {{ selectedType.desc }}
          </div>
          <div class="text-body-2 mt-4">
            {{ $t('YouCanChoose') }} {{ selectedType.maxFlavor }}
            <template v-if="selectedType.maxFlavor < 2">{{ $t('Flavor') }}</template>
            <template v-else>{{ $t('Flavors') }}</template>
            {{ $t('Choose') }}
          </div>
        </div>
        <div
          class="mt-2 px-4"
          style="display:grid;grid-auto-flow: column;grid-auto-columns: 128px;grid-gap: 12px;overflow-x: scroll"
        >
          <v-card
            :color="selectedFlavors.includes(f.id)?'grey darken-3':''" @click="selectFlavor(f.id)"
            v-for="f in flavors" :key="f.id"
          >
            <v-responsive style="height: 100%" :aspect-ratio="2">
              <div class="d-block overflow-hidden text-center">
                <v-avatar class="mt-2 mb-auto">
                  <v-img :src="GlobalConfig.resourceRoot+f.image"/>
                </v-avatar>
                <div class="text-body-2 text-truncate mt-2">
                  {{ f.name }}
                </div>
                <div class="mt-auto mb-2 text-caption text--secondary">
                  + {{ f.price | priceDisplay }}
                </div>
              </div>
            </v-responsive>
          </v-card>
        </div>
        <div
          v-if="step>0"
          style="position: fixed;left: 5vw;right: 5vw;bottom: 20px;" class="d-flex"
        >
          <v-card
            @click="step--"
            color="grey lighten-2 black--text"
            style="border-radius: 30px;"
            class="pa-4 mr-2 d-flex justify-center align-center"
          >
            <v-icon color="black">mdi-arrow-left</v-icon>
          </v-card>
          <v-card
            :disabled="canNotOrder"
            :loading="loading"
            @click="submitOrder"
            :color="!canNotOrder?'primary black--text':''"
            style="border-radius: 30px;"
            class="pa-4 d-flex flex-grow-1 justify-center align-center"
          >
            <div class="ml-4">{{ $t('Sure') }}</div>
            <v-icon right size="18" color="black">mdi-check</v-icon>
          </v-card>
        </div>


      </template>

    </div>


  </div>
</template>

<script>

import i18n from "@/plugins/locales/i18n";
import {addHotpotDishToTable, hotpotDishInfo} from "@/dataLayer/repository/HotpotViewmodel";
import {meanBy, sumBy} from "lodash-es";
import {GlobalConfig} from "@/dataLayer/repository/GlobalSettingManager";


export default {
  name: "HotpotOpenTablePage",
  data: function () {
    return {
      selectedPersonCount: null,
      step: 0,
      selectedTypeName: i18n.t('SingleFlavorKettle3'),
      dishInfo: null,
      selectedFlavors: [],
      loading: false,
    };
  },
  watch: {
    selectedTypeName() {
      this.selectedFlavors = []
    }
  },
  mounted() {
    this.loadInfo()
  },
  computed: {
    GlobalConfig() {
      return GlobalConfig
    },
    flavors() {
      return this.selectedType?.select?.map(it => {
        return {
          id: it.value,
          name: it.text,
          image: it.image,
          price: it.price,
          priceInfo: it.priceInfo,
          groupId: this.selectedType.id
        }
      }) ?? []
    },
    type() {
      return this.dishInfo?.easyMod?.map(it => {
        return {
          name: it.name,
          desc: it.select.map(it => it.text).join(', '),
          maxFlavor: it.maxCount,
          price: meanBy(it.select, 'price') * it.maxCount,
          recommended: it.maxCount === '2',
          id: it.id,
          select: it.select
        }
      }) ?? []
    },
    maxFlavors() {
      return parseInt(this.selectedType.maxFlavor)
    },
    selectedImage() {
      return this.flavors.filter(it => this.selectedFlavors.includes(it.id)).map(it => it.image)
    },
    canNotOrder() {
      return this.selectedFlavors.length !== this.maxFlavors
    },
    selectedFlavorsValue() {
      return this.flavors.filter(it => this.selectedFlavors.includes(it.id))
    },
    selectedType() {
      return this.type.find(it => it.name === this.selectedTypeName)
    },
    totalPrice() {
      return sumBy(this.selectedFlavorsValue, 'price')
    },
  },
  methods: {
    async loadInfo() {
      this.dishInfo = await hotpotDishInfo()
      console.log(this.dishInfo)
    },
    selectType(name) {
      this.selectedTypeName = name
      this.step++
    },
    async submitOrder() {
      this.loading = true
      const res = await addHotpotDishToTable(this.dishInfo, this.selectedFlavorsValue)
      this.loading = false
      this.$emit('close')
      console.log(res)
    },
    selectFlavor(id) {
      if (this.selectedFlavors.includes(id)) {
        this.selectedFlavors = this.selectedFlavors.filter(it => it !== id)
      } else {
        if (this.selectedFlavors.length >= this.maxFlavors) {
          this.selectedFlavors.shift()
        }
        this.selectedFlavors.push(id)
      }
      this.selectedFlavors = [...this.selectedFlavors]
    }
  }
}
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}

</style>
