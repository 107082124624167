import hillo from "hillo";
import {GlobalConfig} from "@/dataLayer/repository/GlobalSettingManager";
import {dishesInTable} from "@/dataLayer/service/api";
import {findDishByCode} from "@/dataLayer/service/dishService";
import CartService from "@/dataLayer/service/CartService";

async function getCurrentConsumeTypeList() {
  try {
    return (await hillo.get("ConsumeType.php?op=showCurrentConsumeType", {lang: GlobalConfig.requestLocale}))?.content ?? []
  } catch (e) {
    return []
  }
}

async function getConsumeTypeById(id) {
  return (await getCurrentConsumeTypeList()).find(it => parseInt(it.id) === parseInt(id))
}

export async function isHotpot() {
  return (await getConsumeTypeById(GlobalConfig.ConsumeTypeId))?.isHotpot === '1'
}

export async function shouldShowHotpotPanel() {
  const isTable = await isHotpot()
  const currentOrders = await dishesInTable()
  console.log(GlobalConfig.extraHotpotCategoryId)
  const notOrder = !listHaveHotpotDish(currentOrders)
  return isTable && notOrder

}

export function listHaveHotpotDish(list) {
  return list.find(it => it.code === 'FS') ||
    list.find(it => it.categoryId.toString() === GlobalConfig.extraHotpotCategoryId)
}

export async function hotpotDishInfo() {
  const dish = await findDishByCode('FS')
  try {
    dish.easyMod = (dish?.modInfo ?? []).map(group => {
      group.select = []
      if (!Array.isArray(group.selectName)) {
        group.selectName = group.selectName.split(',')
        group.selectValue = group.selectValue.split(',')
        group.priceInfo = group.priceInfo.split(',')
        group.selectImage = group.selectImage.split(',')
      }
      group.selectName.forEach((name, index) => {
        group.select.push({
          text: `${name}`,
          value: group.selectValue[index],
          price: parseFloat(group.priceInfo[index]),
          image: group.selectImage[index],
          priceInfo: parseFloat(group.priceInfo[index]) === 0 ? '' : `(€${parseFloat(group.priceInfo[index]).toFixed(2)})`
        })
      })
      return group
    })
  } catch (e) {
    console.log(e)
  }

  return dish
}


export async function addHotpotDishToTable(rfDish, selectedInfo) {
  try {
    const dishDocker = CartService
    const apply = []
    for (const flavor of selectedInfo) {
      apply.push({groupId: flavor.groupId, selectId: [flavor.id]})
    }
    rfDish.apply = apply
    if (await shouldShowHotpotPanel()) {
      dishDocker.add(rfDish, 1)
    }
    return true
  } catch (e) {
    console.log(e)
    return false
  }
}
