<template>
  <div>
    <div
      style="height: calc(100vh - 64px - 48px)" class="d-flex flex-column justify-center align-center"
      v-if="loading"
    >
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <template v-else>
      <template v-if="tableInUse">
        <v-card class="d-flex align-center" tile style="position: sticky;top: 48px;z-index: 1" flat>
          <v-btn
            icon
            class="ml-2"
            @click="expandAllCategories = !expandAllCategories"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <div
            ref="categoryTab"
            class="px-2"
            style="align-items: center;display: grid;grid-auto-flow: column;grid-auto-columns: min-content;grid-gap: 4px;overflow-x: scroll"
          >

            <div
              v-for="(category) in categoryWithDishesInfo"
              @click="gotoCategory(category)"
              :id="'navItem'+category.id"
              :class="category.id===activeCategoryId?'primary--text font-weight-black':''"
              class="text-no-wrap categoryChipItem text-capitalize" :key="category.id"
            >
              {{ category.name }}
            </div>
          </div>


        </v-card>
        <div class="pb-12">
          <div :key="category.id" v-for="category in categoryWithDishesInfo">
            <div
              v-intersect="{handler:e=>onIntersect(e,category),options:{
          }}" :id="'category'+category.id"
            ></div>
            <div class="px-4 py-2 mt-2">
              <div class="text-body-1 amber--text text--lighten-4 font-weight-bold text-capitalize">{{
                  category.name
                }}
              </div>
              <div class="text-body-2">{{ category.desc }}</div>
            </div>
            <dish-card
              @click="currentDish=dish;showDishDetailSheet=true"
              @add="updateCart(dish,1)"
              @minus="currentTotalCount(dish.code)>0?updateCart(dish,-1):null"
              v-for="dish in category.dishes"
              :key="dish.id"
              :image-root="GlobalConfig.imageRoot"
              :dish="dish"
              :count="currentTotalCount(dish.code)"

            />
          </div>
        </div>
      </template>
      <template v-else>
        <div style="height: calc(100vh - 64px - 48px)">
          <loading-page></loading-page>
        </div>
      </template>

    </template>

    <v-dialog fullscreen v-model="expandAllCategories">
      <v-card color="black" tile style="position: relative">
        <div class="pa-4">
          <div class="d-flex align-center" style="width: 100%">

            <div class="text-h6">
              {{ $t('MenuOverview') }}
            </div>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="expandAllCategories = false"
            >
              <v-icon>
                mdi-close-circle
              </v-icon>
            </v-btn>
          </div>


          <div>
            <div v-if="noDrinkCategory !== []">
              <div class="text-body-2 text--secondary mt-6">{{ $t('Meal') }}</div>
              <div
                class="mt-4"
                style="display: grid;grid-template-columns: repeat(3,minmax(0,1fr));grid-gap: 8px"
              >
                <v-card
                  v-for="category in noDrinkCategory"
                  flat
                  @click="gotoCategory(category); expandAllCategories = false" :key="category.id"
                >
                  <v-img
                    style="position: absolute;top:0;left: 0;right: 0;bottom: 0;border-radius: 8px"
                    :src="GlobalConfig.resourceRoot+category.dcImage"
                    gradient="to bottom, rgba(0,0,0,.5), rgba(0,0,0,.33)"
                  />
                  <v-responsive :aspect-ratio="1">
                    <div style="height: 100%" class="text-h6 pa-2 font-weight-black">
                      {{ category.name }}
                    </div>
                  </v-responsive>


                </v-card>
              </div>
            </div>
            <div v-if="drinkCategory!==[]">
              <div class="text-body-2 text--secondary mt-6">{{ $t('Drinks') }}</div>
              <div
                class="mt-4"
                style="display: grid;grid-template-columns: repeat(3,minmax(0,1fr));grid-gap: 8px"
              >
                <v-card
                  v-for="category in drinkCategory"
                  flat
                  @click="gotoCategory(category); expandAllCategories = false" :key="category.id"
                >
                  <v-img
                    style="position: absolute;top:0;left: 0;right: 0;bottom: 0;border-radius: 8px"
                    :src="GlobalConfig.resourceRoot+category.dcImage"
                    gradient="to bottom, rgba(0,0,0,.5), rgba(0,0,0,.33)"
                  />
                  <v-responsive :aspect-ratio="1">
                    <div style="height: 100%" class="text-h6 pa-2 font-weight-black text-capitalize">
                      {{ category.name }}
                    </div>
                  </v-responsive>


                </v-card>
              </div>
            </div>
          </div>
        </div>

      </v-card>

    </v-dialog>
    <v-card
      elevation="4"
      light
      color="primary"
      class="d-flex pa-4 align-center justify-center"
      @click="showCart=!showCart" v-if="dishCount>0"
      style="position: fixed;bottom: 76px; right :5vw;left: 5vw; min-width:140px;z-index: 100;border-radius: 30px;"
    >

      <v-badge :content="dishCount" color="secondary" overlap offset-x="20">
        <v-icon class="mr-2">mdi-shopping-outline</v-icon>
      </v-badge>
      <div class="text-body-1 font-weight-bold ml-4 text-capitalize">{{ $t('Cart') }}</div>
    </v-card>
    <dish-detail-sheet
      :show="showDishDetailSheet"
      @modification-cancel="cancelEdit"
      @modification-submit="submitModification"
      :image-root="GlobalConfig.imageRoot"
      :dish="currentDish"
    />
    <v-bottom-sheet scrollable fullscreen v-model="showCart">
      <v-card style="overflow: scroll" class="pa-4 black">
        <div class="d-flex justify-center pt-3" style="width: 100%;position: relative">
          <div class="text-h6 font-weight-bold">
            {{ $t('ShoppingCart') }}
          </div>
          <v-btn style="position: absolute;right: 16px;top:10px" icon @click="showCart=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div style="width: 100%" class="d-flex flex-column justify-center align-center mt-12">
          <div class="text-body-2 text--secondary font-weight-medium">{{ $t('Total') }}</div>
          <div class="mt-2 text-h4 font-weight-bold">
            {{ totalPriceWithoutMod | priceDisplay }}
          </div>
          <div class="text-caption text--secondary mt-2">{{ $t('IncludeVat') }}</div>
        </div>
        <div class="pb-16 mt-12">
          <v-card
            elevation="0"
            style="border-radius: 12px;"
            color="grey darken-4"
            class="pa-2 mb-3 mx-4 d-flex align-center"
            v-for="dish in cartList"
            :key="dish.id"
          >
            <div class="pa-1 black" style="border-radius: 8px;height: min-content">
              <dish-image
                :width="48" :image-root="GlobalConfig.imageRoot" :image="dish.image" :cloud-image="dish.cloudImage"
              />
            </div>

            <div class="flex-grow-1 ml-3 d-flex flex-column" style="min-height: 56px">
              <div class="font-weight-medium text-body-1">{{ dish.dishName }}</div>
              <div v-if="dish.displayApply.length>0" class="text-body-2 text--secondary mt-1">
                {{ dish.displayApply.map(it => it.value).join(', ') }}
              </div>
              <v-spacer></v-spacer>
              <div class="mt-1 text-body-2">
                {{ dish.realPrice | priceDisplay }}
              </div>
            </div>
            <div class=" d-flex align-center grey darken-3 mr-1" style="border-radius: 24px;">
              <v-card
                color="grey darken-2" class="pa-2" style="border-radius: 24px" elevation="0"
                @click="dish.change(-1)"
              >
                <v-icon>mdi-minus</v-icon>
              </v-card>
              <div class="pa-2">
                {{ dish.count }}
              </div>
              <v-card
                color="grey darken-1" class="pa-2" style="border-radius: 24px" elevation="0"
                @click="dish.change(1)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-card>
            </div>
          </v-card>
        </div>
        <v-card
          :disabled="submittingCart"
          color="primary black--text" class="pa-4 d-flex justify-center"
          style="border-radius: 30px; position: fixed;left: 16px;right: 5vw;bottom: 16px;z-index: 10"
          @click="submitOrder"
        >
          <template v-if="submittingCart">
            <v-progress-circular indeterminate></v-progress-circular>
          </template>
          <template v-else>
            <v-icon color="black" class="mr-4">mdi-check</v-icon>
            <div class="text-body-1 mr-2 font-weight-bold text-capitalize">{{ $t('OrderNow') }}</div>
          </template>
        </v-card>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="showHotpotPage" fullscreen persistent scrollable>
      <v-card color="black" tile>
        <hotpot-open-table-page @close="showHotpotPage=false"/>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import DishDetailSheet from '@/views/components/DishDetailSheet.vue'
import {getTableInfo, orderDish} from '@/dataLayer/service/api'
import CartService from '@/dataLayer/service/CartService'
import {defaultRestaurantInfo, getCurrentRestaurantInfo} from '@/dataLayer/repository/restaurantInfo'
import DishCard from '@/views/components/DishCard.vue'
import {GlobalConfig} from "@/dataLayer/repository/GlobalSettingManager"
import DishImage from "@/views/components/DishImage.vue";
import LoadingPage from "@/views/pages/LoadingPage.vue";
import {listHaveHotpotDish, shouldShowHotpotPanel} from "@/dataLayer/repository/HotpotViewmodel";
import HotpotOpenTablePage from "@/views/pages/HotpotOpenTablePage.vue";
import {getCategoryWithDishes} from "@/dataLayer/service/dishService";
import IKUtils from "innerken-js-utils";


export default {
  name: "MainPage",
  components: {HotpotOpenTablePage, LoadingPage, DishImage, DishCard, DishDetailSheet},
  data: function () {
    return {
      loading: true,
      showHotpotPage: false,


      activeCategoryId: null,
      expandAllCategories: false,
      drinkCategory: [],
      noDrinkCategory: [],
      showDishDetailSheet: false,
      showRestaurantInfo: false,
      currentDish: null,
      categoryWithDishesInfo: [],
      restaurantInfo: defaultRestaurantInfo,
      cart: CartService,

      searchWord: null,
      show: false,
      GlobalConfig,
      showCart: false,
      currentTableInfo: null,


      submittingCart: false,
    }
  },
  computed: {
    dishCount: function () {
      return this.cartList.length > 0 ? this.cart.count() : 0
    },
    cartList: function () {
      return this.cart.list?.length > 0 ? this.cart.list : []
    },
    totalPriceWithoutMod: function () {
      return this.cartList.length > 0 ? this.cart.total() : 0
    },
    totalPriceBeforeDiscount: function () {
      return parseFloat(this.totalPriceWithoutMod)
    },
    filteredDish: function () {
      return this.categoryWithDishesInfo.reduce((arr, i) => {
        arr.push(...i.dishes.filter(d => {
          return d.code.toLowerCase().includes(this.searchWord) || d.dishName.toLowerCase().includes(this.searchWord)
        }))
        return arr
      }, [])
    },
    tableInUse: function () {
      console.log(this.currentTableInfo)
      return this.currentTableInfo?.usageStatus === '1'
    }
  },
  methods: {
    changeActiveCategoryId(categoryId) {
      this.activeCategoryId = categoryId
      this.$refs.categoryTab.scrollLeft = [...this.$refs.categoryTab.childNodes.values()]
        .find(it => it.id === 'navItem' + categoryId).offsetLeft - 48
    },
    currentTotalCount(code) {
      return this.cartList.filter(it => it.code === code).reduce((sum, i) => sum + parseInt(i.count), 0)
    },
    onIntersect(entries, category) {
      if (entries[0].boundingClientRect.top < 160) {
        this.changeActiveCategoryId(category.id)
      }
    },
    gotoCategory: function (category) {
      this.$vuetify.goTo(`#category${category.id}`, {offset: 90})
    },
    addDish: function (dish, count = 1) {
      this.updateCart(dish, count)
    },
    submitModification: function (mod, count = 1) {
      const apply = []
      const dish = this.currentDish
      if (dish.modInfo) {
        for (const i of dish.modInfo) {
          const item = {}
          item.groupId = i.id
          item.selectId = i.selectValue.filter((s, index) => {
            return [(mod[i.id] ?? [])].flat().includes(index)
          })
          if (i.required === '1' && item.selectId === '') {
            item.selectId = i.selectValue[0]
          }
          apply.push(item)
        }
        dish.apply = apply
      }

      this.addDishWithMod(dish, count)
      this.currentDish = null
      this.showDishDetailSheet = false
    },
    addDishWithMod: function (dish, count) {
      this.updateCart(dish, count)
    },
    updateCart: function (dish, count) {

      console.log(this.cart)
      setTimeout(() => {
        this.cart.add(dish, count)
      }, 3)
    },
    cancelEdit() {
      this.$nextTick(() => {
        this.showDishDetailSheet = false
      })
    },
    async submitOrder() {
      this.submittingCart = true
      try {
        await orderDish(this.cartList)
        this.cart.clear()
      } catch (e) {
        console.log(e)
        IKUtils.showError(e?.message)
      }
      
      this.submittingCart = false
    }
  },
  watch: {
    dishCount(val) {
      if (val === 0) {
        this.showCart = false
      }
    }
  },
  async mounted() {
    this.loading = true
    this.categoryWithDishesInfo = await getCategoryWithDishes('DE')
    this.drinkCategory = this.categoryWithDishesInfo.filter(i => i.dishesCategoryTypeId === "9")
    this.noDrinkCategory = this.categoryWithDishesInfo.filter(i => i.dishesCategoryTypeId !== "9")
    this.restaurantInfo = await getCurrentRestaurantInfo()
    this.currentTableInfo = await getTableInfo()
    if (this.currentTableInfo.usageStatus === '1') {
      this.showHotpotPage = await shouldShowHotpotPanel() && !listHaveHotpotDish(this.cartList)
    }

    this.loading = false
  }
}
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}

</style>
