<template>
  <div>
    <template v-if="loading">
      <div class="d-flex flex-column justify-center align-center" style="height: calc(100vh - 128px)">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
    </template>
    <template v-else>
      <div @click="$router.push('/menu')">
        <v-responsive :aspect-ratio="1.618">
          <v-carousel
            :show-arrows="false"
            cycle
            delimiter-icon="mdi-circle-small"
            height="100%"
            hide-delimiter-background
          >
            <v-carousel-item
              v-for="(image) in imageList"
              :key="image.id"
            >
              <v-img :aspect-ratio="1.618" :src="GlobalConfig.imageRoot+image.image"></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-responsive>
      </div>
      <div class="mt-n4 mx-6">

        <v-card
          v-if="!tableUsing" class="pa-2" flat style="border-radius: 12px;display: grid;
      grid-template-columns: repeat(2,minmax(0,1fr));grid-gap: 24px"
        >
          <div
            class="d-flex pa-4 flex-column justify-center align-center"
            @click="shouldOpenTable"
          >
            <div class="text-h6 text-truncate font-weight-black amber--text text--lighten-4" style="max-width: 145px;">
              {{ $t('ScanCodeDineIn') }}

            </div>
            <div class="text-body-2 text-truncate text--secondary" style="max-width: 110px;">
              {{ $t('DeliciousFoodGlance') }}
            </div>
            <v-img class="mt-3" contain src="img/homePage/restaurant.svg" width="64"/>
          </div>
          <div
            class="d-flex pa-4 flex-column justify-center align-center"
            @click="shouldOpenTable"
          >
            <div class="text-h6 text-truncate font-weight-black amber--text text--lighten-4" style="max-width: 145px;">
              {{ $t('PickAndTakeAway') }}
            </div>
            <div class="text-body-2 text-truncate text--secondary" style="max-width: 110px;">
              {{ $t('AdditionalDiscount') }}
            </div>
            <v-img class="mt-3" contain src="img/homePage/shopping-bag.svg" width="64"/>
          </div>
        </v-card>
        <v-card v-else class="pa-4 py-6 d-flex align-center" flat style="border-radius: 12px">
          <v-avatar size="36">
            <v-img src="img/homePage/restaurant.svg"></v-img>
          </v-avatar>
          <div class="d-block overflow-hidden ml-4">
            <div class="text-body-1 font-weight-black">
              {{ $t('CurrentTableNumber') }}{{ currentOrderInfo.name }}
            </div>
            <div
              class="text-truncate text-caption text--secondary"
            >
              {{ $t('ContinueEnjoyFood') }}
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn color="primary black--text" @click="$router.push('/menu')">{{ $t('Go') }}</v-btn>
        </v-card>
        <v-card v-if="isLogin" class="mt-4 pa-4 px-4 d-flex align-center" flat style="border-radius: 12px">
          <v-avatar size="36">
            <v-img src="img/homePage/woman.svg"></v-img>
          </v-avatar>
          <div class="d-block overflow-hidden ml-4 mr-2">
            <div class="text-body-1 font-weight-black">
              {{ $t('MyNotes') }} {{ 0 }}
            </div>
            <div
              class="text-truncate text-caption text--secondary"
            >
              {{ $t('RedeemableCouponsLimitedMerchandise') }}
            </div>
          </div>
          <v-spacer></v-spacer>
          <div class="mr-4 d-flex flex-column align-center justify-center" @click="showUserCode=true">
            <v-icon size="36">mdi-qrcode</v-icon>
          </div>
        </v-card>
        <v-card v-else class="mt-6 pa-4 px-4 d-flex align-center" flat style="border-radius: 12px">
          <v-avatar size="36">
            <v-img src="img/homePage/woman.svg"></v-img>
          </v-avatar>
          <div class="d-block overflow-hidden ml-4 mr-1">
            <div class="text-body-2 font-weight-black">
              {{ $t('WelcomeLoginBecomeMember') }}
            </div>
            <div
              class="text-truncate text-caption text--secondary"
            >
              {{ $t('EnjoyPointsAndBenefits') }}
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn color="primary black--text" small text @click="startLogin">{{ $t('LoginNow') }}</v-btn>
        </v-card>
        <div class="mt-6">
          <v-card class="pa-4 d-flex align-center" flat style="border-radius: 12px" @click="displayComingPage">
            <v-img class="flex-grow-0" src="img/homePage/voucher.svg" width="36"></v-img>
            <div class="d-block overflow-hidden">
              <div class="text-truncate text-body-2 ml-4 text-capitalize">{{ $t('Coupon') }}</div>
            </div>
          </v-card>
          <v-card class="pa-4 mt-4 d-flex align-center" flat style="border-radius: 12px" @click="displayComingPage">
            <v-img class="flex-grow-0" src="img/homePage/profits.svg" width="36"></v-img>
            <div class="d-block overflow-hidden">
              <div class="text-truncate text-body-2 ml-4 text-capitalize">{{ $t('RechargeCredit') }}</div>
            </div>
          </v-card>
        </div>
      </div>
    </template>
    <v-bottom-sheet v-model="showUserCode">
      <user-code-page/>
    </v-bottom-sheet>

    <v-bottom-sheet v-model="showScanQrCodeDialog" fullscreen>
      <v-card>
        <div class="pa-6 d-flex flex-column " style="height: 100vh">
          <template v-if="!scanResult">
            <div class="text-h5 mt-8">
              <v-icon class="mb-1">mdi-qrcode-scan</v-icon>
              {{ $t('ScanQROnDesk') }}
            </div>
            <div class="text-body-2 text--secondary mt-2">
              {{ $t('ScanQROnDeskHint') }}
            </div>
            <v-card class="mt-8">
              <camera-code-scanner @scan="onScan"></camera-code-scanner>
            </v-card>
            <v-spacer></v-spacer>
            <v-btn large style="width: 100%" @click="showScanQrCodeDialog=false">
              <v-icon left>mdi-close</v-icon>
              {{ $t('AbortScan') }}
            </v-btn>
          </template>
          <template v-else>
            <template v-if="scanHasError">
              <div class="text-h5 mt-8">
                <v-icon class="mb-1" color="error">mdi-qrcode-remove</v-icon>
                {{ $t('QRDamaged') }}<b>{{ $t('QRDamagedHint') }}</b>
              </div>
              <div class="text-body-2 text--secondary mt-2">
                {{ $t('ScanOtherQrOrCallService') }}
              </div>
              <v-spacer></v-spacer>
              <div class="text-body-2">
                {{ $t('QRErrorCode') }}
              </div>
              <div class="text-caption text--secondary mt-2 mb-8">
                HashCode: base64:0x9ad8df872971251238714-{{ scanResult }}-
              </div>
              <v-btn large style="width: 100%" @click="scanResult=''">
                <v-icon color="primary" left>mdi-refresh</v-icon>
                {{ $t('RetryQRScan') }}
              </v-btn>
            </template>
            <template v-else>
              <div class="text-h5 mt-8">
                {{ $t('IsLoading') }}...
              </div>
            </template>

          </template>
        </div>

      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>

import {getRestaurantInfo, getTableInfo, getTableName,} from "@/dataLayer/service/api";
import {mapState} from "vuex";
import UserCodePage from "@/views/pages/UserCodePage.vue";
import {getBannerImageList} from "@/dataLayer/repository/restaurantInfo";
import {GlobalConfig, setTableName} from "@/dataLayer/repository/GlobalSettingManager";
import dayjs from "dayjs";
import {CameraCodeScanner} from "vue-barcode-qrcode-scanner";
import {openOrEnterTable} from "@/dataLayer/repository/TableManage";

export default {
  name: "IndexPage",
  components: {UserCodePage, CameraCodeScanner},
  data: function () {
    return {
      showHotPotOpenTablePage: false,
      loggedIn: false,
      currentOrderInfo: null,
      showUserCode: false,
      showScanQrCodeDialog: null,
      scanResult: '',
      scanHasError: false,
      GlobalConfig,
      loading: true,
      imageList: [],
    };
  },
  computed: {
    ...mapState(['isLogin', 'userInfo']),
    tableUsing() {
      return this.currentOrderInfo?.usageStatus === '1'
    }
  },
  async mounted() {
    await this.reload()
  },
  destroyed() {
    clearInterval(this.handel)
  },
  methods: {
    async reload() {
      this.loading = true
   
      this.currentOrderInfo = (await getTableInfo())
      this.imageList = await getBannerImageList()
      this.GlobalConfig = GlobalConfig
      await this.openAnnouncement()
      this.loading = false
    },
    hashcode(code) {
      let hash = 0, i, chr, len;
      if (code.length === 0) return hash;
      for (i = 0, len = code.length; i < len; i++) {
        chr = code.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0;
      }
      return hash;
    },
    async openAnnouncement() {
      const res = await getRestaurantInfo()
      const today = dayjs().format('YYYY-MM-DD')
      const announce = this.hashcode(res.buffetAnnouncementBody).toString()
      const oldDate = localStorage.getItem('date')
      const oldAnnounce = localStorage.getItem('announce')
      const timespan = dayjs(today).diff(oldDate, 'day')
      if (!oldDate || timespan > 7) {
        localStorage.setItem('date', today)
        this.$store.commit('showAnnouncement', true)
      }
      if (oldAnnounce && oldAnnounce === announce) {
        return
      }
      localStorage.setItem('announce', announce)
      this.$store.commit('showAnnouncement', true)
    },
    displayComingPage() {
      this.$store.commit('showComingPage', true)
    },
    startLogin() {
      this.$store.commit('showLoginPage', true)
    },
    async onScan(event) {

      this.scanResult = event.result
      try {
        window.navigator.vibrate(200);
      } catch (e) {
        console.warn(e)
      }

      try {
        const url = new URL(event.result)
        console.log(url)
        const tableName = url.searchParams.get("tableName") || url.searchParams.get("TableName")
        if (!tableName) {
          this.scanHasError = true
        }
        console.log(tableName, 'tableName')
        setTableName(tableName)
        await this.openTable()
        this.showScanQrCodeDialog = false
      } catch (e) {
        console.log(e)
        this.scanHasError = true
      }


    },
    shouldOpenTable() {
      let tableName = getTableName()
      if (!tableName) {
        this.showScanQrCodeDialog = true
      } else {
        this.openTable()
      }
    },
    async openTable() {
      await openOrEnterTable()
    }
  }
}
</script>

<style scoped>

</style>
