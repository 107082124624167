import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect
} from "firebase/auth";
import {collection, doc, getDoc, getDocs, query} from "firebase/firestore";
import {db, FireBaseAuth, OldFireBaseStore} from "@/plugins/firebase";

const websiteUrl = 'websiteUrls'

export async function logOut() {
  await FireBaseAuth.signOut()
}

export async function loginWithGoogle() {
  const provider = new GoogleAuthProvider()
  return await signInWithRedirect(FireBaseAuth, provider)
}

export async function loginWithApple() {
  const provider = new OAuthProvider('apple.com')
  return await signInWithRedirect(FireBaseAuth, provider)
}

export async function resetPasswordEmail(email) {
  return await sendPasswordResetEmail(FireBaseAuth, email)
}

export async function loginWithEmailAndPassword(email, password) {
  return await signInWithEmailAndPassword(FireBaseAuth, email, password)
}

export async function createUserWithEmail(email, password) {
  return await createUserWithEmailAndPassword(FireBaseAuth, email, password)
}

export async function checkNewRecords() {
  const collectionRef = collection(db, websiteUrl)
  const res = query(collectionRef)
  return (await getDocs(res)).docs.map(it => it.data())
}

export async function checkOldRecords() {
  const collectionRef = collection(OldFireBaseStore, websiteUrl)
  const res = query(collectionRef)
  return (await getDocs(res)).docs.map(it => it.data())
}

export async function getRealConsumeTypeId(storeId) {
  const data = await getDoc(doc(collection(db, 'qrMenu'), storeId.toString()))
  return data.data() ?? []
}
