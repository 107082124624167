<template>
  <div>
    <div v-if="loading" class="d-flex align-center justify-center" style="height: calc(100vh - 64px - 48px)">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <div v-else class="pa-4">
      <template v-if="orderList.length>0">
        <div class="text-subtitle-1 font-weight-bold">
          {{ $t('Ordered') }}
        </div>
        <div class="pb-16 mt-4">
          <v-card
            v-for="dish in orderList"
            :key="dish.id"
            class="pa-2 mb-3 d-flex align-center"
            elevation="0"
            style="border-radius: 12px;"
          >
            <div class="text-body-2 ml-2" style="min-width: 36px">
              {{ dish.count }}&times;
            </div>
            <div class="pa-1 black" style="border-radius: 8px;height: min-content">
              <dish-image
                :image="dish.image" :image-root="GlobalConfig.imageRoot" :cloud-image="dish.cloudImage" :width="36"
              />
            </div>

            <div class="flex-grow-1 ml-3">
              <div class="font-weight-medium text-body-2">{{ dish.name }}</div>
              <div v-if="dish.displayApply.length>0" class="text-body-2 text--secondary mt-1">
                {{ dish.displayApply.map(it => it.value).join(', ') }}
              </div>


            </div>

            <div class="pa-2">

              <div class="mt-1 text-no-wrap text-body-2 text--secondary">
                {{ dish.realPrice | priceDisplay }}
              </div>

            </div>

          </v-card>
        </div>
      </template>
      <template v-else>
        <div class="d-flex flex-column align-center justify-center" style="height: calc(100vh - 64px - 48px);">
          <v-icon x-large>mdi-noodles</v-icon>
          <div class="text-body-1 mt-8">
            {{ $t('YouNotOrderedDishes') }}
          </div>
          <v-btn color="primary black--text mt-2" @click="$router.push('/menu')">
            {{ $t('OrderNow') }}
          </v-btn>
        </div>
      </template>

    </div>
    <v-card v-if="orderList.length>0" class="pa-4 d-flex" style="position: fixed;left: 0;right: 0;bottom: 60px;" tile>
      <div class=" d-flex align-center">
        <v-icon class="mr-2" size="18">mdi-format-list-bulleted-square</v-icon>
        <div class="text-body-1 font-weight-medium">
          {{ totalPriceOrderedDish | priceDisplay }}
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        v-if="showCheckoutButton === '1'" color="primary black--text" rounded
        @click="EBonStatus ? showReviewPage = true : showPaymentSelect = true"
      >
        {{ $t('Checkout') }}
      </v-btn>
    </v-card>
    <v-bottom-sheet v-model="showReviewPage">
      <v-list color="black">
        <v-subheader>{{ $t('Total') }}</v-subheader>
        <v-list-item>
          <v-list-item-title>
            {{ totalPriceOrderedDish | priceDisplay }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <div class="text--secondary"> {{ $t('wannaUseEBon') }}</div>
            <div class="text-caption"> {{ $t('useEBonSaveALot') }}</div>
          </v-list-item-title>
        </v-list-item>
        <template v-if="!checkEmail">
          <v-list-item>
            <v-btn class="mt-2" color="primary black--text" width="100%" @click="checkOrderInfo">
              <v-icon left>mdi-check</v-icon>
              {{ $t('yes') }}
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn class="my-2" color="grey lighten-5 black--text" width="100%" @click="notUseEBon">
              <v-icon left>mdi-close</v-icon>
              {{ $t('no') }}
            </v-btn>
          </v-list-item>
        </template>
        <template v-else>
          <v-list-item>
            <v-text-field v-model="email" :label="$t('inputEmail')"></v-text-field>
          </v-list-item>
          <v-list-item>
            <div class="d-flex" style="width: 100%">
              <v-btn class="my-2 mr-2" color="grey lighten-5 black--text" @click="notUseEBon">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-btn :loading="btnLoading" class="my-2 flex-grow-1" color="primary black--text" @click="sendEmail">
                <v-icon left>mdi-email-fast-outline</v-icon>
                {{ $t('sendEmail') }}
              </v-btn>
            </div>
          </v-list-item>
        </template>
      </v-list>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="showTipPage">
      <v-list color="black">
        <v-subheader>{{ $t('Total') }}</v-subheader>
        <v-list-item>
          <v-list-item-title>
            {{ totalPriceOrderedDish | priceDisplay }}
          </v-list-item-title>
        </v-list-item>
        <v-subheader>{{ $t('GiveTip') }}?</v-subheader>
        <v-list-item
          v-for="p in tipPercentage"
          :key="p"
          @click="submitTip(p)"
        >
          <v-list-item-avatar>
            <v-icon>mdi-face-man-shimmer</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>+ {{ p }}%</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action-text style="white-space: nowrap">{{ totalPriceOrderedDish * p * 0.01 | priceDisplay }}
          </v-list-item-action-text>
        </v-list-item>
        <v-list-item @click="submitTip(0)">
          <v-list-item-avatar>
            <v-icon>mdi-emoticon-wink-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ $t('NoThanks') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="showCommentPage">
      <v-list color="black">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('ThanksForReview') }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('ReviewHint') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-textarea :label="$t('ReviewLabel')" :placeholder="$t('ReviewPlaceHolder') + '😀'"></v-textarea>
        </v-list-item>
        <v-list-item>
          <v-btn block color="primary black--text" @click="submitComment">
            {{ $t('Submit') }}
          </v-btn>
        </v-list-item>


      </v-list>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="showPaymentSelect">
      <v-list color="black">
        <v-subheader>{{ $t('Total') }}</v-subheader>
        <v-list-item>
          <v-list-item-title>
            {{ totalPriceOrderedDish | priceDisplay }}
          </v-list-item-title>
          <v-list-item-action-text v-if="tipAmount>0" style="white-space: nowrap">
            + {{ tipAmount | priceDisplay }} {{ $t('Top') }}
          </v-list-item-action-text>

        </v-list-item>
        <v-subheader>{{ $t('PaymentMethod') }}</v-subheader>
        <v-list-item @click="payByMethod(2)">
          <v-list-item-avatar>
            <v-icon>mdi-credit-card</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ $t('CreditCard') }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="payByMethod(1)">
          <v-list-item-avatar>
            <v-icon>mdi-cash</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ $t('Bar') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="showCompletePage" fullscreen persistent>
      <v-card tile>
        <order-complete @return="showCompletePage=false"/>
      </v-card>

    </v-bottom-sheet>
  </div>
</template>

<script>
import AadenBase from "aaden-base-model";
import {
  checkElectronicBillingStatus,
  dishesInTable,
  forceGetSystemSetting,
  getCurrentInfo,
  requireCheckOut,
  sendMailTo
} from "@/dataLayer/service/api";
import DishImage from "@/views/components/DishImage.vue";
import {GlobalConfig} from "@/dataLayer/repository/GlobalSettingManager";
import OrderComplete from "@/views/pages/OrderComplete.vue";

const OrderedDishFactory = AadenBase.StandardDishesListFactory()
export default {
  name: "OrderPage",
  components: {OrderComplete, DishImage},
  data: function () {
    return {
      showCheckoutButton: '',
      btnLoading: false,
      EBonStatus: false,
      orderInfo: [],
      email: '',
      checkEmail: false,
      orderedDish: OrderedDishFactory,
      GlobalConfig,
      loading: true,
      showPaymentSelect: false,
      showTipPage: false,
      showCommentPage: false,
      showReviewPage: false,
      showCompletePage: false,
      tipAmount: 0,
      tipPercentage: [15, 10, 5]
    };
  },
  computed: {
    orderList: function () {
      return this.orderedDish.list?.length > 0 ? this.orderedDish.list : []
    },
    totalPriceOrderedDish: function () {
      return this.orderList.length > 0 ? this.orderedDish.total() : 0
    },
  },
  methods: {
    payByMethod(id) {
      console.log(id)
      this.showPaymentSelect = false
      this.showCompletePage = true
      requireCheckOut()

    },
    submitComment() {
      this.showCommentPage = false
      this.showPaymentSelect = true
    },
    notUseEBon() {
      this.checkEmail = false
      this.showPaymentSelect = true
      this.showReviewPage = false
    },
    async checkOrderInfo() {
      const res = JSON.parse(localStorage.getItem("userOrder"))
      this.email = res?.email ?? ''
      if (res?.orderUuid !== this.orderInfo?.electronicUuid || !res) {
        this.checkEmail = true
      } else {
        this.notUseEBon()
      }
    },
    async sendEmail() {
      this.btnLoading = true
      const id = this.orderInfo?.electronicUuid
      const userOrder = {
        orderUuid: id,
        email: this.email
      }
      localStorage.setItem('userOrder', JSON.stringify(userOrder))
      await sendMailTo(this.email, id)
      this.btnLoading = false
      this.notUseEBon()
    },
    submitTip(tipPercentage) {
      this.tipAmount = this.totalPriceOrderedDish * 0.01 * tipPercentage
      this.showTipPage = false
      this.showPaymentSelect = true
    }
  },
  async mounted() {
    this.loading = true
    this.showCheckoutButton = await forceGetSystemSetting({
      section: 'qrApp',
      sKey: 'showCheckOutButton',
      sValue: '1',
      defaultValue: '1',
      sType: 'boolean',
      minimumVersion: '1.7.784',
      sOptions: '',
      tagList: 'basic'
    })
    const dishes = await dishesInTable()
    this.orderedDish.clear()
    this.orderedDish.loadTTDishList(dishes)
    this.EBonStatus = await checkElectronicBillingStatus()
    console.log(this.orderedDish, 'Ordered Dish')
    this.orderInfo = (await getCurrentInfo()).order
    this.loading = false
  }
}
</script>

<style scoped>

</style>
