import hillo from "hillo";
import IKUtils from "innerken-js-utils";
import {LocalSettingManager} from "biewangle";
import {getBaseAndUrlForDeviceId, getCurrentConsumeTypeId, reportToCloud} from "@/dataLayer/service/api";
import {checkNewRecords, checkOldRecords, getRealConsumeTypeId} from "@/dataLayer/service/firebaseAPI";
import {getCurrentRestaurantInfo} from "@/dataLayer/repository/restaurantInfo";

export const GlobalConfig = LocalSettingManager.config({
  locale: "de",
  SaaSVersion: false,
  DeviceId: -1,
  ConsumeTypeId: 5,
  imageRoot: '',
  resourceRoot: '',
  requestLocale: "de",
  languageChanged: false,
});
window.Config = GlobalConfig;

export async function loadConfig() {
  const isDebug = () => {
    try {
      return process.env.NODE_ENV === "development"
    } catch (e) {
      return false
    }
  }
  const shortName = (isDebug() ? 'innerken' : location.hostname.split(".")[0]).toLowerCase();
  const newRecords = (await checkNewRecords()).find(s => s?.subdomainName?.toLowerCase() === shortName)
  const oldRecords = (await checkOldRecords()).find(s => s?.subdomainName?.toLowerCase() === shortName)
  const overrideConsumeTypeId = IKUtils.getQueryString("ConsumeTypeId")
  let record
  if (newRecords) {
    record = newRecords
    GlobalConfig.SaaSVersion = true
  } else {
    record = oldRecords
  }
  console.log(record, 'record')
  try {
    if (record) {
      GlobalConfig.DeviceId = record.deviceId;
      initUrls((await getBaseAndUrlForDeviceId(record.deviceId)).url)
      const adminConsumeTypeId = await getCurrentConsumeTypeId()
      const needEditConsumeTypeId = adminConsumeTypeId === '-1' ? (await getRealConsumeTypeId(record.deviceId)).ConsumeTypeId : adminConsumeTypeId
      GlobalConfig.ConsumeTypeId = overrideConsumeTypeId || needEditConsumeTypeId || GlobalConfig.ConsumeTypeId
      GlobalConfig.extraHotpotCategoryId = record?.extraHotpotCategoryId ?? -1
    } else {
      initUrls(location.host)
      const adminConsumeTypeId = await getCurrentConsumeTypeId()
      GlobalConfig.ConsumeTypeId = overrideConsumeTypeId || (adminConsumeTypeId === '-1' ? GlobalConfig.ConsumeTypeId : adminConsumeTypeId)
      const jsonConfig = (await hillo.get('AccessLog.php?op=config'))?.content
      GlobalConfig.DeviceId = jsonConfig.DeviceId
      GlobalConfig.extraHotpotCategoryId = jsonConfig?.extraHotpotCategoryId ?? -1
    }
  } catch (e) {
    console.log(e, 'error on load realconsumeTypeId')
  }
  try {
    await reportToCloud({
      deviceId: GlobalConfig.DeviceId,
      ip: "",
      uuid: location.host,
      version: require("../../../package.json").version,
      frontendType: "scan-qr-v2",
      name: (await getCurrentRestaurantInfo()).name,
    })
  } catch (e) {
    console.log(e)
  }
}

function initUrls(base) {
  base = base.replaceAll('https://', '')
  base = base.replaceAll('http://', '')
  const override = IKUtils.getQueryString("Base")
  const realUrl = `${location.protocol}//${override || base}`
  hillo.initial(realUrl + '/PHP/')
  GlobalConfig.imageRoot = getImgRoot(realUrl);
  GlobalConfig.resourceRoot = realUrl + "/Resource/";
}

export function getImgRoot(base) {
  return base + '/Resource/dishImg/';
}

export let TableName =
  IKUtils.getQueryString("tableName") ||
  IKUtils.getQueryString("TableName");

export function setTableName(tableName) {
  TableName = tableName
}



