<template>
  <v-card v-if="userInfo" class="pa-4 py-6 primary black--text" tile>
    <div class="text-h6 text-capitalize">
      {{ $t('MyMembershipCode') }}
    </div>
    <div class="mt-4 d-flex align-center">
      <v-avatar class="mr-4">
        <v-img src="img/homePage/woman.svg"></v-img>
      </v-avatar>
      <div>
        <div class="text-body-2 font-weight-bold">{{ $t('Hey') }}, {{ userInfo.displayName || userInfo.uid }}</div>
        <v-card style="width: min-content;white-space: nowrap" class="px-4 success darken-1 mt-1 text-caption" flat>
          {{ $t('Member') }}
        </v-card>
      </div>
    </div>

    <v-card flat color="white" class="mt-8 pa-8 py-12 d-flex align-center justify-center flex-column">
      <div class="text-body-1 black--text font-weight-bold text-center">
        {{  $t('ShowMemberCodeStoreEarnPoints') }}
      </div>
      <v-img width="200" height="200"
             :src="'https://quickchart.io/qr?text=='+userInfo.uid+'+++timestamp='+timeStamp">
        <template #placeholder>
          <div style="height: 100%;width: 100%" class="d-flex flex-column justify-center align-center">
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
        </template>
      </v-img>
      <div class="text-caption black--text">{{ $t('AutomaticUpdateEverySeconds') }}</div>
    </v-card>
    <v-card class="d-block overflow-hidden" color="grey lighten-4 pa-6" flat>
      <div class="text-truncate text-body-2 text-center black--text">
        {{ $t('DiscountFeaturesStayTuned') }}!
      </div>
    </v-card>


  </v-card>
</template>

<script>
import {mapState} from "vuex";
import dayjs from "dayjs";

export default {
  name: "UserCodePage",
  computed: {
    ...mapState(['userInfo'])
  },
  data: function () {
    return {
      timeStamp: 0,
      handel: null,
    };
  },
  mounted() {
    this.handel = setInterval(() => {
      this.timeStamp = dayjs().unix()
    }, 30 * 1000)
  },
  destroyed() {
    clearInterval(this.handel)
  }
}
</script>

<style scoped>

</style>
