import hillo from "hillo";
import {GlobalConfig} from "@/dataLayer/repository/GlobalSettingManager";
import dayjs from "dayjs";
import {getImageCache} from "@/dataLayer/service/api";
import {keyBy} from "lodash-es";

const dishListCache = []
let categoryCache = []

export async function getCategoryWithDishes() {
  if (categoryCache.length === 0) {
    const imageCache = keyBy(await getImageCache(), "dishesId")
    categoryCache = (await hillo.get('Category.php?op=withConsumeType',
      {
        consumeTypeId: GlobalConfig.ConsumeTypeId,
        lang: GlobalConfig.requestLocale,
        onlyActive: 0,
        chaos: dayjs().unix()
      })).content.sort((a, b) => {
      const rank = '10,9,8,11,13'.split(',')
      const idToRank = (id) => {
        return 10 - rank.indexOf(id.toString())
      }
      const [ra, rb] = [a.dishesCategoryTypeId, b.dishesCategoryTypeId].map(idToRank)
      return ra > rb ? -1 : 1
    })
      .map(c => {
        c.dishes = c.dishes.filter(it => !it.code.startsWith('ea'))
          .map(d => {
            d.dishName = d.dishName ?
              d.dishName.replaceAll('[/chili/]', '🌶️')
                .replaceAll('[/gutou/]', '🦴') : ''
            d.count = 0
            d.modShow = false
            d.note = ''
            d.cloudImage = imageCache[d.dishId]?.imagePath
            if (d.isFree === '1') {
              d.price = 0
            }
            return d
          })
        dishListCache.push(...c.dishes)
        c.categoryName = c.langs[0].name
        c.desc = c.langs[0].desc
        return c
      })
      .filter(c => c.dishes.length > 0)
  }

  return categoryCache
}

const allDishListCache = []

async function getAllDishes() {
  if (allDishListCache.length === 0) {
    allDishListCache.push(...(await hillo.get('Dishes.php?onlyActive=0', {lang: GlobalConfig.requestLocale})).content)
  }
  return allDishListCache
}


export async function findDishByCode(code) {//仅用来搞火锅
  return (await getAllDishes()).find(it => it.code === code)
}
