import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './plugins/router'
import i18n from '@/plugins/locales/i18n'
import GlobalConfig, {loadConfig} from '@/dataLayer/repository/GlobalSettingManager'
import IKUtils from 'innerken-js-utils'
import store from './plugins/store'
import {FireBaseAuth} from "@/plugins/firebase";
import {forceGetSystemSetting} from "@/dataLayer/service/api";

Vue.config.productionTip = false

Vue.filter('priceDisplay',
    function (price) {
      const res = parseFloat(price).toFixed(2)
      if (store.state.currencySymbols === '$') {
        return store.state.currencySymbols + ' ' + res.replace(',', '.')
      } else {
        return res.replace('.', ',') + ' ' + store.state.currencySymbols
      }
    }
)


const deviceId = IKUtils.getQueryString("deviceId") || IKUtils.getQueryString("DeviceId")

// const tableName = IKUtils.getQueryString("TableName")


async function init() {
    if (deviceId !== null) {
        GlobalConfig.Base = deviceId
        await localStorage.setItem('deviceId', deviceId)
    }
    await loadConfig()
  const symbol = await forceGetSystemSetting({
    section: 'currencySymbols',
    sKey: 'useCurrencySymbols',
    sValue: '€',
    defaultValue: '€',
    sType: 'string',
    minimumVersion: '1.7.784',
    sOptions: '',
    tagList: 'basic'
  })
  const needAcceptAllOrder = await forceGetSystemSetting({
    section: 'qrApp',
    sKey: 'needAcceptAllOrder',
    sValue: '0',
    defaultValue: '0',
    sType: 'boolean',
    minimumVersion: '1.7.784',
    sOptions: '',
    tagList: 'basic,qrApp'
  })
  const cantOrderDishes = await forceGetSystemSetting({
    section: 'qrApp',
    sKey: 'cantOrderDishes',
    sValue: '0',
    defaultValue: '0',
    sType: 'boolean',
    minimumVersion: '1.7.784',
    sOptions: '',
    tagList: 'basic,qrApp'
  })
  store.commit('currencySymbols', symbol)
  store.commit('needAcceptAllOrder', needAcceptAllOrder)
  store.commit('cantOrderDishes', cantOrderDishes)
    new Vue({
        vuetify,
        router,
        i18n,
        store,
        render: h => h(App)
    }).$mount('#app')
}

init()
FireBaseAuth.onAuthStateChanged(user => {
    console.log(user)
    if (user?.uid) {
        store.commit('changeLogIn', true)
        store.commit('changeUserInfo', user)
        store.commit('showLoginPage', false)
    } else {
        store.commit('changeLogIn', false)
    }

})

