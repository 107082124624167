import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginStateReady: false,
    showLoginPage: false,
    showAnnouncement: false,
    showComingPage: false,
    showAGB: false,
    isLogin: false,
    userInfo: null,
    currencySymbols: '',
    needAcceptAllOrder: '',
    cantOrderDishes: ''
  },
  getters: {},
  mutations: {
    cantOrderDishes (state, cantOrderDishes) {
      state.cantOrderDishes = cantOrderDishes
    },
    needAcceptAllOrder (state, needAcceptAllOrder) {
      state.needAcceptAllOrder = needAcceptAllOrder
    },
    currencySymbols (state, symbols) {
      state.currencySymbols = symbols
    },
    changeAGB(state, show) {
      state.showAGB = show
    },
    changeUserInfo(state, info) {
      state.userInfo = info
    },
    showComingPage(state, show) {
      state.showComingPage = show
    },
    showAnnouncement(state, show) {
      state.showAnnouncement = show
    },
    loginStateReady(state) {
      state.loginStateReady = true
    },
    showLoginPage(state, show = true) {
      state.showLoginPage = show
    },
    changeLogIn(state, login = true) {
      state.isLogin = login
    }
  },
  actions: {},
  modules: {}
})
