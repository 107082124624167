<template>
  <div class="d-flex align-center flex-column justify-center" style="height: 100vh;">
    <lottie-animation
      v-cloak
      ref="animation"
      :animationData="require('@/assets/wait.json')"
      :autoPlay="true"
      style="max-width: 300px"
    ></lottie-animation>
    <v-fade-transition>
      <div
        v-if="show" class="d-flex flex-column flex-wrap align-center pa-4 justify-center text-center" style="width: 80%"
      >
        <div class="text-h5">
          {{ $t('Please wait..') }}
        </div>
        <div class="text-body-2 mt-2">
          {{ $t('CheckoutPleaseWaitMoment') }}
        </div>
        <div class="text-body-1 mt-2">
          {{ $t('YourTotalPrice') }}
          <span class="amber--text font-weight-black text--lighten-4">{{ totalPrice | priceDisplay }}</span>.
        </div>
        <v-btn class="mt-4" color="primary black--text" @click="$emit('return')">
          <v-icon left>mdi-check</v-icon>
          {{ $t('BackToMainPage') }}
        </v-btn>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'
import {getCurrentInfo} from '@/dataLayer/service/api' // import lottie-web-vue


export default {
  components: {LottieAnimation},
  name: "OrderComplete",
  data: function () {
    return {show: false, totalPrice: 0}
  },
  async mounted() {
    setTimeout(() => {
      this.$refs.animation.play()
      setTimeout(() => {
        this.show = true
      }, 1000)
    }, 1000)
    this.totalPrice = (await getCurrentInfo()).order.totalPrice
  }
}
</script>

<style scoped>

</style>
