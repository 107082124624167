<template>
  <v-card class="ma-4 d-flex" elevation="0" style="border-radius: 16px" @click="$emit('click')">
    <v-lazy min-height="128" width="100%">
      <div class="d-flex align-center" style="height: 100%;width: 100%">
        <div class="flex-grow-0 black ml-2" style="border-radius: 16px;">
          <dish-image :cloud-image="dish.cloudImage" :image="dish.image" :image-root="imageRoot" :width="116"/>
        </div>
        <div class="d-flex flex-column pa-3 ml-2 flex-grow-1 font-weight-medium" style="width: 100%;height: 100%">
          <div class="text-body-2 font-weight-bold">
            {{ dish.code.replaceAll('.', '') }}.<span class="text-capitalize">{{ dishName }}</span>
          </div>
          <div
            class="text-body-2 mt-1 text--secondary"
            v-html="dish.dishDesc ? dish.dishDesc : $t('EmptyMenuDesc') "
          ></div>
          <div
            v-if="dish.AllergenName"
            class="text-body-2 mt-1 text--secondary"
            v-html="dish.AllergenName"
          ></div>
          <v-spacer></v-spacer>
          <div class="d-flex align-center mt-1" style="width: 100%">
            <div class="text-body-2 font-weight-medium ">
              {{ dish.price | priceDisplay }}
            </div>
            <v-spacer></v-spacer>
            <template v-if="dish.isActive==='1'">
              <div v-if="store.state.cantOrderDishes !== '1'" class="d-flex">
                <template v-if="dish.haveMod!=='0'&&count>0">
                  <v-card
                    class=" text-caption" color="primary  black--text"
                    style="border-radius: 24px;padding: 0px 6px"
                  >
                    &times; {{ count }}
                  </v-card>
                </template>
                <template v-if="dish.haveMod==='0'&&count>0">
                  <v-icon color="primary darken-2" @click.stop="$emit('minus')">mdi-minus-circle</v-icon>
                  <div class="text-center text-body-1" style="min-width: 24px;">
                    {{ count }}
                  </div>
                </template>
                <v-icon
                  v-if="dish.haveMod==='0'||count===0"
                  color="primary"
                  @click.stop="dish.haveMod==='0'?$emit('add'):$emit('click')"
                >
                  mdi-plus-circle
                </v-icon>
              </div>
            </template>
            <template v-else>
              <v-icon>mdi-cube-off-outline</v-icon>
            </template>
          </div>
        </div>
      </div>
    </v-lazy>
  </v-card>
</template>

<script>
import DishImage from "@/views/components/DishImage.vue";
import store from "@/plugins/store";

export default {
  name: "DishCard",
  components: {DishImage},
  props: {
    imageRoot: {},
    dish: {},
    count: {},
  },
  computed: {
    store() {
      return store
    },
    dishName() {
      return this.dish.name ?? this.dish.dishName
    },
    code() {
      return this.dish.code.replaceAll('.', '')
    }
  }
}
</script>

<style scoped>

</style>
