import hillo from "hillo"

import i18n from '@/plugins/locales/i18n'
import {GlobalConfig, TableName} from '@/dataLayer/repository/GlobalSettingManager'
import dayjs from "dayjs";
import {shouldShowHotpotPanel} from "@/dataLayer/repository/HotpotViewmodel";
import store from "@/plugins/store";


export async function openTableWithScanQR(tableId) {
  return (await hillo.post('Complex.php?op=openTableWithScanQR', {
    tableId,
    overrideConsumeTypeId: GlobalConfig.ConsumeTypeId
  }))
}

export function getTableName() {
  return TableName
}


export async function getCurrentInfo() {
  const tableId = (await getTableInfo()).id
  try {
    return (await hillo.silentGet('Tables.php?op=currentInfo', {id: tableId})).content

  } catch (e) {
    return {order: {consumeTypeStatusId: -1}}
  }
}

export async function dishesInTable() {
  const tableId = (await getTableInfo()).id
  try {
    return (await hillo.silentGet('Complex.php?op=dishesInTable',
      {tableId, lang: GlobalConfig.requestLocale})).content
  } catch (e) {
    return []
  }
}

export async function getTableInfo() {
  const tableName = getTableName()
  if (!tableName) {
    return null
  } else {
    return (await hillo.silentGet('Tables.php', {name: tableName})).content[0]
  }
}

export async function requireCheckOut() {
  const tableId = (await getTableInfo()).id
  return (await hillo.get('Complex.php?op=requireCheckOut',
      {lang: i18n.locale, tableId})
  ).content
}

export async function orderDish(order) {
  const id = (await getTableInfo()).id
  const haveFSDish = order.find(it => it.code === 'FS')
  if (haveFSDish) {
    const shouldAddFSDish = await shouldShowHotpotPanel()
    if (!shouldAddFSDish) {
      order = order.filter(it => it.code !== 'FS')
    } else {
      haveFSDish.count = 1
    }
  }
  return await hillo.post('Complex.php?op=addDishesToTable', {
    params: JSON.stringify(order),
    tableId: id,
    printingKitchenBon: print ? 1 : 0,
    pending: store.state.needAcceptAllOrder === '1' ? 1 : 0
  })
}


export async function getRestaurantInfo() {
  return (await hillo.get('Restaurant.php?op=view')).content[0]
}


export const cloudUrl = 'https://cloud5.api.aaden.io'

export async function getBaseAndUrlForDeviceId(deviceId) {
  const url = (await findDeviceByDeviceId(deviceId))
    ?.baseUrl ?? getNgrokUrl(deviceId)
  return {
    deviceId,
    url
  }
}

export function getNgrokUrl(deviceId) {
  deviceId = deviceId.toString()
  return `ik${deviceId.padStart(4, '0')}.ngrok.aaden.io`
}

export async function findDeviceByDeviceId(deviceId) {
  try {
    return (await hillo.jsonPost(cloudUrl + '/virtualDevice/search', {deviceId: deviceId})).data?.[0]
  } catch (e) {
    console.log(e)
    return null
  }
}

export async function sendMailTo(mailTo, uuid) {
  try {
    await hillo.jsonPost('https://sendinvoicemail-evhwqutgjq-ey.a.run.app', {
      mailTo,
      uuid,
      templateId: 'z3m5jgr8xpoldpyo'
    })
  } catch (e) {
    console.log(e, 'e')
  }
}

export async function checkElectronicBillingStatus() {
  return (await hillo.get('Complex.php?op=checkAadenPoint', {})).content.enabled
}

export async function forceGetSystemSetting(item) {
  return (await hillo.post('Restaurant.php?op=forceGetSystemSetting', {
    systemSetting: JSON.stringify(item)
  })).content
}

export async function getCurrentConsumeTypeId() {
  return (await forceGetSystemSetting({
    section: 'qrApp',
    sKey: 'currentConsumeTypeId',
    sValue: '-1',
    defaultValue: '-1',
    sType: 'string',
    minimumVersion: '1.7.784',
    sOptions: '',
    tagList: 'basic,qrApp'
  }))
}


/**
 * @param {{deviceId: number}} data
 */
export async function reportToCloud(data) {
  const defaultData = {
    name: '', ip: '', uuid: '', version: '', frontendType: '', deviceId: '',
  };
  const {
    name,
    ip,
    uuid,
    version,
    frontendType,
    deviceId,
  } = Object.assign({}, defaultData, data);
  const frontendLogDTO = {
    name,
    ip,
    uuid,
    version,
    frontendType,
    deviceId,
    timestamp: dayjs().valueOf()
  };
  await hillo.jsonPost("https://cloud-v2.aaden.io/api/frontend-logs/save", frontendLogDTO)
}


export async function getImageCache() {
  try {
    return await hillo.get("https://cloud-v2.aaden.io/api/dish-images/by-device/" + GlobalConfig.DeviceId)
  } catch (e) {
    return []
  }

}
