import en from '@/plugins/locales/translations/en-US.json'
import es from '@/plugins/locales/translations/es.json'
import pt from '@/plugins/locales/translations/pt-PT.json'
import de from '@/plugins/locales/translations/de.json'
import fr from '@/plugins/locales/translations/fr-FR.json'
import nl from '@/plugins/locales/translations/nl.json'
import ar from '@/plugins/locales/translations/ar.json'
import ko from '@/plugins/locales/translations/ko.json'
import ru from '@/plugins/locales/translations/ru.json'
import zh from '@/plugins/locales/translations/zh-Hans.json'
import ja from '@/plugins/locales/translations/ja.json'
import pl from '@/plugins/locales/translations/pl.json'
import it from '@/plugins/locales/translations/it.json'
import {GlobalConfig} from "@/dataLayer/repository/GlobalSettingManager";

const supported = ['en', 'es', 'pt', 'de', 'fr', 'ar', 'ko', 'ru', 'zh', 'ja', 'pl', 'nl', 'it']
let locale = 'de'

try {
  // get browser default language
  if (!GlobalConfig.languageChanged) {
    const {0: browserLang} = navigator.language.split('-')
    if (supported.includes(browserLang)) {
      locale = browserLang
    } else {
      locale = GlobalConfig.locale
    }
    if (["de", "en", "zh"].includes(locale.toLowerCase())) {
      GlobalConfig.requestLocale = locale.toUpperCase()
    } else {
      GlobalConfig.requestLocale = "EN"
    }
  } else {
    locale = GlobalConfig.locale
  }

} catch (e) {
  console.log(e)
}

export default {
  // current locale
  locale,

  // when translation is not available fallback to that locale
  fallbackLocale: 'de',

  // availabled locales for user selection
  availableLocales: [{
    code: 'en',
    flag: 'us',
    label: 'English',
    messages: en
  }, {
    code: 'nl',
    flag: 'nl',
    label: 'Netherlands',
    messages: nl
  }, {
    code: 'es',
    flag: 'es',
    label: 'Español',
    messages: es
  }, {
    code: 'pt',
    flag: 'pt',
    label: 'Português',
    messages: pt
  }, {
    code: 'de',
    flag: 'de',
    label: 'Deutsch',
    messages: de
  }, {
    code: 'fr',
    flag: 'fr',
    label: 'Français',
    messages: fr
  }, {
    code: 'ar',
    flag: 'sa',
    label: 'العربية',
    messages: ar
  }, {
    code: 'ko',
    flag: 'kr',
    label: '한국어',
    messages: ko
  }, {
    code: 'ru',
    flag: 'ru',
    label: 'русский',
    messages: ru
  }, {
    code: 'zh',
    flag: 'cn',
    label: '中文',
    messages: zh
  }, {
    code: 'ja',
    flag: 'jp',
    label: '日本語',
    messages: ja
  }, {
    code: 'pl',
    flag: 'pl',
    label: 'Polskie',
    messages: pl
  },

    {
      code: 'it',
      flag: 'it',
      label: 'Italy',
      messages: it
    }
  ]
}
