import {getFirestore} from 'firebase/firestore'
import {initializeApp} from 'firebase/app'
import {getAuth} from 'firebase/auth'


const oldFirebaseConfig = {
    apiKey: 'AIzaSyATTtRB1PlOC-At0-ndKqap4RABwwCc6ew',
    authDomain: 'aaden-boss-test.firebaseapp.com',
    projectId: 'aaden-boss-test',
    storageBucket: 'aaden-boss-test.appspot.com',
    messagingSenderId: '604566804841',
    appId: '1:604566804841:web:a82cb7788517358ceb45f8',
    measurementId: 'G-CST41XTVMJ'
}

const newFirebaseConfig = {
    apiKey: 'AIzaSyCtvQ3d-HAtHTUg_-505c-qXRnlz8RlZeg',
    authDomain: 'aaden-saas.firebaseapp.com',
    projectId: 'aaden-saas',
    storageBucket: 'aaden-saas.appspot.com',
    messagingSenderId: '169167876904',
    appId: '1:169167876904:web:b83934e5a34d1cbfcc161d',
    measurementId: 'G-QRPH7NLDZS',
}

export const oldFirebase = initializeApp(oldFirebaseConfig, 'oldFirebase')
export const OldFireBaseStore = getFirestore(oldFirebase)
export const firebaseApp = initializeApp(newFirebaseConfig, 'firebaseApp')

export const FireBaseAuth = getAuth(firebaseApp)


export const db = getFirestore(firebaseApp)

