<template>
  <div class="mr-0 text-center">
    <v-menu bottom class="languageMenu" content-class="languageMenu" transition="slide-y-transition">
      <template v-slot:activator="{ on }">
        <v-card
          class="pa-2"
          color="transparent"
          flat
          v-on="on"
        >
          <span class="d-flex align-center">
            <flag-icon :flag="getLocale.flag" class="mr-3"></flag-icon>
            <span class="text-caption">{{ getLocale.label }}</span>
          </span>
        </v-card>
      </template>
      <v-list>
        <v-list-item
          v-for="item in allLocales"
          :key="item.code"
          @click="setLocale(item.code)">
          <v-list-item-title class="d-flex align-center">
            <flag-icon :flag="item.flag"></flag-icon>
            <span class="ma-2">{{ item.label }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import FlagIcon from './FlagIcon.vue'
import {GlobalConfig} from "@/dataLayer/repository/GlobalSettingManager";

export default {
  name: 'LangSwitch',
  components: {
    FlagIcon
  },
  computed: {
    getLocale() {
      return this.$i18n.locales.find(el => el.code === this.$i18n.locale)
    },
    allLocales() {
      return this.$i18n.locales.filter(el => el.code !== this.$i18n.locale)
    }
  },
  methods: {
    setLocale(locale) {

      this.$i18n.locale = locale
      GlobalConfig.locale = locale
      GlobalConfig.languageChanged = true
      if (["de", "en", "zh"].includes(locale.toLowerCase())) {
        GlobalConfig.requestLocale = locale.toUpperCase()
      } else {
        GlobalConfig.requestLocale = "EN"
      }
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  }
}

</script>

<style>
.languageSwitch {
  height: 36px;
}

.languageSwitch span .ma-2 {
  font-variant: none;
  font-size: 0.90rem;
}

.languageMenu {
  top: 50px !important;
}
</style>
