import {getTableInfo, openTableWithScanQR} from "@/dataLayer/service/api";
import router from "@/plugins/router";

export async function openOrEnterTable() {
    const {usageStatus, id} = await getTableInfo()
    try {
        if (usageStatus === '1') {
            router.push('/menu')
        } else {
            const res = await openTableWithScanQR(id)
            if (res.status === 'good') {
                router.push('/menu')
            }
        }
    } catch (e) {
        console.log(e)
    }

}
