<template>
  <v-bottom-sheet v-model="realShow" class="dishDetailSheet" max-width="600px" width="100vw">
    <v-card
      v-if="dish" id="card"
      class="d-flex flex-column align-center"
      max-height="calc(100vh - 24px)"
      style="border-top-right-radius: 16px;border-top-left-radius: 16px;position: relative;overflow: scroll"
    >
      <div style="max-height: calc(100vh - 24px);overflow-y: scroll;width: 100%">
        <v-img
          :aspect-ratio="16/12"
          :src="dish.cloudImage"
          width="100%"
        >
          <template #placeholder>
            <v-card>
              <v-responsive :aspect-ratio="1">
                <div class="d-flex align-center justify-center" style="height: 100%">
                  <v-icon large>mdi-image</v-icon>
                </div>
              </v-responsive>
            </v-card>
          </template>
        </v-img>
        <div class="pa-4" style="width: 100%">
          <div class="text-h6 font-weight-black">{{ dish.dishName }}</div>
          <div class="text-body-2 text--secondary mt-1" v-html="dish.dishDesc || $t('EmptyMenuDesc')"></div>
          <div
            v-if="dish.AllergenName"
            class="text-body-2 mt-1 text--secondary"
            v-html="dish.AllergenName"
          ></div>
        </div>
        <template v-if="dish.modInfo&&show&&computedOption.length>0">
          <div class="pb-16 px-4" style="width: 100%;">
            <v-divider class="mb-3"/>
            <template v-for="item in computedOption">
              <div :key="'mod2head'+item.id" class="text-subtitle-2 d-flex align-center">
                {{ item.name }}
                <v-spacer></v-spacer>
                <v-card
                  v-if="item.multiSelect==='1'&&mod[item.id]&&mod[item.id].length>0" class="px-2 py-0" color="primary black--text" elevation="0"
                  style="border-radius: 24px"
                >
                  + {{ mod[item.id] && mod[item.id].length }}
                </v-card>
              </div>
              <div :key="'mod2'+item.id" class="mt-1">
                <v-item-group
                  v-model="mod[item.id]"
                  :mandatory="item.required==='1'"
                  :multiple="item.multiSelect==='1'"
                  active-class="active"
                >
                  <div class="d-flex flex-wrap">
                    <v-item v-for="(s,index) in item.select" :key="'mod111'+index" v-slot="{active,toggle}">
                      <v-card
                        :color="active?'primary black--text':'grey darken-3'" class="mr-2 mb-2 pa-2"
                        elevation="0"
                        @click="toggle"
                      >
                        <div class="text-body-2 text-center d-flex  justify-center">
                          <div>{{ s.text }}</div>
                          <div>{{ s.priceInfo }}</div>
                        </div>

                      </v-card>
                    </v-item>
                  </div>

                </v-item-group>
              </div>
            </template>
          </div>
          <div class="flex-shrink-0" style="height: 96px;width: 100%"></div>
        </template>
        <div class="flex-shrink-0" style="height: 144px;width: 100%"></div>
        <v-card
          v-if="dish.isActive==='1'" class="pa-2 px-4" color="grey darken-3" style="position: fixed;bottom: 0;width: 100%;"
          tile
        >
          <div class="d-flex align-center">
            <div>
              <div class="text-h6 font-weight-bold">
                {{ realPrice | priceDisplay }}
              </div>
              <div v-if="selectedModString" class="d-flex align-center">
                <v-icon class="mr-2 text--secondary" small>mdi-heart-outline</v-icon>
                <div class="text-caption text--secondary">{{ selectedModString }}</div>
              </div>
            </div>
            <v-spacer></v-spacer>
            <div v-if="store.state.cantOrderDishes !== '1'" class=" d-flex align-center grey darken-3 mr-1" style="border-radius: 24px;">
              <v-card
                class="pa-3" color="grey darken-2" elevation="0" style="border-radius: 24px"
                @click="count>1?count--:null"
              >
                <v-icon>mdi-minus</v-icon>
              </v-card>
              <div class="pa-2 mx-1">
                {{ count }}
              </div>
              <v-card
                class="pa-3" color="grey darken-1" elevation="0" style="border-radius: 24px"
                @click="count++"
              >
                <v-icon>mdi-plus</v-icon>
              </v-card>
            </div>

          </div>
          <div class="d-flex mt-2">
            <v-card
              class="pa-3 mr-2 d-flex align-center" color="error" style="border-radius: 24px"
              @click="cancel"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-card>
            <v-card
              v-if="store.state.cantOrderDishes !== '1'"
              class="d-flex align-center justify-center pa-3 px-6 flex-grow-1" color="primary black--text"
              dark
              elevation="0" style="border-radius: 24px"
              @click="submitModification"
            >
              <v-icon class="mr-2" color="black">mdi-basket-plus</v-icon>
              <div class="text-body-1 font-weight-bold">
                {{ realPrice * count|priceDisplay }}
              </div>
            </v-card>
          </div>


        </v-card>
      </div>

    </v-card>
  </v-bottom-sheet>
</template>

<script>
import store from "@/plugins/store";

export default {
  name: "DishDetailSheet",
  props: {show: {}, dish: {}, imageRoot: {}},
  data: function () {
    return {
      selected: false,
      mod: {},
      count: 1,
    };
  },

  computed: {
    store() {
      return store
    },
    options() {
      return this.dish.modInfo ?? []
    },
    computedOption: function () {
      const realModInfo = []
      this.options.forEach(item => {
        item.select = []
        if (!Array.isArray(item.selectName)) {
          item.selectName = item.selectName.split(',')
          item.selectValue = item.selectValue.split(',')
          item.priceInfo = item.priceInfo.split(',')
        }
        item.selectName.forEach((name, index) => {
          item.select.push({
            text: `${name}`,
            value: item.selectValue[index],
            price: parseFloat(item.priceInfo[index]),
            priceInfo: parseFloat(item.priceInfo[index]) === 0 ? '' : `(€${parseFloat(item.priceInfo[index]).toFixed(2)})`
          })
        })
        realModInfo.push(item)
      })
      return realModInfo
    },
    realMod: function () {
      const realMod = []
      for (const groupId in this.mod) {
        for (const selectIndex of [this.mod[groupId]].flat()) {
          realMod.push({
            groupId,
            selectIndex
          })
        }
      }
      return realMod
    },
    addPrice: function () {
      return this.computedOption.length > 0 ? this.realMod.reduce((total, i) => {
        total += this.findModItemUseGroupIdAndIndex(i.groupId, i.selectIndex)?.price ?? 0
        return total
      }, 0) ?? 0 : 0
    },
    selectedModString: function () {
      return this.computedOption.length > 0 ? this.realMod
        .map(it => this.findModItemUseGroupIdAndIndex(it.groupId, it.selectIndex)?.text ?? '').join(', ') : ''
    },
    realPrice: function () {
      return parseFloat(this.dish.price) + parseFloat(this.addPrice)
    },
    realShow: {
      get: function () {
        return this.show
      },
      set: function () {
        this.cancel()
      }
    }
  },
  methods: {
    findModItemUseGroupIdAndIndex(groupId, index) {
      return this.computedOption.find(g => parseInt(g.id) === parseInt(groupId)).select[parseInt(index)]
    },
    cancel() {
      this.$emit('modification-cancel')
      this.count = 1
      this.mod = {}
    },
    submitModification() {
      this.$emit('modification-submit', this.mod, this.count)
      this.mod = {}
      this.count = 1
    }
  }
}
</script>

<style scoped>
.contentContainer {
  padding: 16px;
}

::-webkit-scrollbar {
  display: none;
}


.name {
  font-feature-settings: "kern", "ss01", "ss05", "ss07";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.75rem;
  line-height: 2.5rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  color: rgb(32, 33, 37);
  margin: 0px;
}

</style>
