import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '@/views/pages/MainPage.vue'
import LoadingPage from "@/views/pages/LoadingPage.vue"
import ErrorPage from '@/views/pages/ErrorPage.vue'
import OrderComplete from '@/views/pages/OrderComplete.vue'
import NotPayPage from "@/views/pages/NotPayPage.vue";
import OrderPage from "@/views/pages/OrderPage.vue";
import MyPage from "@/views/pages/MyPage.vue";
import IndexPage from "@/views/pages/IndexPage.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'loading',
    component: LoadingPage
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage
  },
  {
    path: '/menu',
    name: 'Menu',
    component: MainPage
  },
  {
    path: '/order',
    name: 'order',
    component: OrderPage
  },
  {
    path: '/my',
    name: 'My',
    component: MyPage
  },
  {
    path: '/index',
    name: 'index',
    component: IndexPage
  },
  {
    path: '/end',
    name: 'End',
    component: OrderComplete
  },
  {
    path: '/notPaid',
    name: 'notPaid',
    component: NotPayPage
  }
]

const router = new VueRouter({
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes
})

export default router
